import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import IM000116 from './../images/IM000116.jpeg';
export const _frontmatter = {
  "title": "About Me!",
  "path": "/aboutmeIM000116.JPG.html"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <img className="mx-auto" src={IM000116} />
    <p>{`Hi! I'm Tarik Hamilton. That's all the information I could tell you except my age which is 10. The kid to the left is MysticSonic. He's 12. It's not his real name though. If you like to meet him go download a `}<a parentName="p" {...{
        "href": "mirc.com"
      }}>{`mIRC®`}</a>{`. The IRC stands for Internet Relay Chat. Uh... bye.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      